import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import NotFoundOrFormSubmittedLayout from '../components/NotFoundOrFormSubmittedLayout'
import Layout from '../components/Layout'

import submittedImg from '../images/success.gif'

export default function FormSubmittedSuccessfully() {

  const data = useStaticQuery(graphql`{
    allWp {
      nodes {
        acfOptionsOptionsPage {
          optionsPage {
            copyrightField
          }
        }
      }
    }
    allWpMenu {
      ...getMenus
    }
  }`)

  const { allWpMenu: {menus}, allWp: { nodes } } = data
  const { acfOptionsOptionsPage: { optionsPage: { copyrightField } } } = nodes[0]

  const mainMenu = menus.filter(menu => menu.locations[0] === 'MENU_1');
  const footerMenu = menus.filter(menu => menu.locations[0] === 'FOOTER_MENU');

  return (
    <Layout mainMenu={mainMenu} footerMenu={footerMenu} copyrightField={copyrightField} title="Thank you page">
      <NotFoundOrFormSubmittedLayout
        classes="page404" 
        copyright={copyrightField}
        imageSrc={submittedImg}
        imageAlt="Form submitted"
        title="Thank you!"
        text="Your message has been sent successfully, so you can expect to hear from us very soon." 
      />
    </Layout>
  )
}
